import type { Dispatch, FC, PropsWithChildren, SetStateAction } from 'react'
import type { SortingState } from '@tanstack/react-table'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  type Column,
  type ColumnDef,
  type Table as ReactTable,
  type RowData,
  getSortedRowModel,
} from '@tanstack/react-table'
import { TableContainer } from './table-container'
import { textStyle } from '../utils/text-styles'
import { ArrowDown, ArrowUp } from 'lucide-react'
import { tv } from 'tailwind-variants'
import { Spinner } from './spinner'
import { twMerge } from 'tailwind-merge'

const DEFAULT_MESSAGES = {
  error: 'Server encountered an error while loading data',
  empty: 'No data available for selected criteria',
}

export type DataTableMessages = typeof DEFAULT_MESSAGES & {
  emptyIcon?: FC
}

export interface DataTableProps<Data extends RowData> {
  columns: ColumnDef<Data>[]
  data: Data[]
  isLoading?: boolean
  isError?: boolean
  sortingState?: SortingState
  setSortingState?: Dispatch<SetStateAction<SortingState>>
  messages?: Partial<DataTableMessages>
  className?: string
}

export const DataTable = <Data extends object>({
  children,
  isLoading,
  isError,
  data,
  columns,
  sortingState,
  setSortingState,
  messages,
  className,
  ...rest
}: PropsWithChildren<DataTableProps<Data>>) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: sortingState,
    },

    getCoreRowModel: getCoreRowModel(),
    enableSortingRemoval: true,

    onSortingChange: setSortingState,
    getSortedRowModel: getSortedRowModel(),
  })

  const isSuccess = !isLoading && !isError
  return (
    <TableContainer>
      <table className={twMerge('border-separate [border-spacing:0.75rem]', className)} {...rest}>
        <DataTableHead table={table} />

        {isLoading && <LoadingTableContent table={table} />}
        {isError && (
          <ErrorTableContent table={table} messages={Object.assign(DEFAULT_MESSAGES, messages)} />
        )}
        {isSuccess && data.length === 0 && (
          <EmptyTableContent table={table} messages={Object.assign(DEFAULT_MESSAGES, messages)} />
        )}

        {isSuccess && data.length > 0 && <DataTableBody table={table} />}
      </table>
    </TableContainer>
  )
}

const headingVariants = tv({
  base: 'flex items-center justify-left select-none gap-1',
  variants: {
    sortable: {
      true: 'cursor-pointer hover:opacity-75 transition-opacity duration-150 ease-in-out',
      false: '',
    },
  },
  defaultVariants: {
    sortable: false,
  },
})

export const DataTableHead = <TData extends object>({ table }: { table: ReactTable<TData> }) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            const meta = header.column.columnDef.meta
            const canSort = header.column.getCanSort()
            const isSorted = header.column.getIsSorted()

            return (
              <th
                key={header.id}
                onClick={header.column.getToggleSortingHandler()}
                title={getColumnHeaderTitle(header.column)}
                {...meta}
              >
                <div className={headingVariants({ sortable: canSort })}>
                  {typeof header.column.columnDef.header === 'function' ? (
                    header.column.columnDef.header({ header, column: header.column, table })
                  ) : (
                    <span className={textStyle('body')}>{header.column.columnDef.header}</span>
                  )}
                  {canSort && isSorted ? (
                    isSorted === 'asc' ? (
                      <ArrowUp size={16} />
                    ) : (
                      <ArrowDown size={16} />
                    )
                  ) : null}
                </div>
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

export const DataTableBody = <TData extends object>({ table }: { table: ReactTable<TData> }) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row, rowIndex) => (
        <tr key={row.id} className={textStyle('body')}>
          {row.getVisibleCells().map((cell) => {
            return (
              <td key={cell.id} {...cell.column.columnDef.meta}>
                {cell.column.id === 'rowNumber'
                  ? rowIndex + 1
                  : flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  )
}

function getColumnHeaderTitle<T extends object>(column: Column<T, unknown>): string {
  if (!column.getCanSort()) return ''

  const fallbackTitle =
    typeof column.columnDef.header === 'string' ? `Sort by '${column.columnDef.header}'` : ''

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return column.columnDef.meta?.title ?? fallbackTitle
}

export interface LoadingTableContentProps<TData> {
  table: ReactTable<TData>
}
export const LoadingTableContent = <TData extends object>({
  table,
}: LoadingTableContentProps<TData>) => {
  return (
    <tbody>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className="flex flex-col py-6 items-center gap-1 text-gray-700">
            <Spinner />
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export interface EmptyTableContentProps<TData> {
  table: ReactTable<TData>
  messages: DataTableMessages
}
export const EmptyTableContent = <TData extends object>({
  messages,
  table,
}: EmptyTableContentProps<TData>) => {
  return (
    <tbody>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className="flex flex-col py-6 text-center gap-1 text-gray-700">
            <div className={textStyle('body')}>{messages.empty}</div>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export interface ErrorTableContentProps<TData> {
  table: ReactTable<TData>
  messages: DataTableMessages
  onReload?: () => void
}
export const ErrorTableContent = <TData extends object>({
  table,
  messages,
}: ErrorTableContentProps<TData>) => {
  return (
    <tbody>
      <tr>
        <td colSpan={table.getAllColumns().length}>
          <div className="flex flex-col py-6 text-center gap-1 text-gray-700">
            <div className={textStyle('body')}>{messages.error}</div>
          </div>
        </td>
      </tr>
    </tbody>
  )
}
