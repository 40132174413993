'use client'

import { add, sub } from 'date-fns'
import { getDaysOfMonthGroupedByWeeks } from '../utils/prompt'
import { textStyle } from '@1337-us/io-components/util'
import { Tooltip } from 'react-tooltip'
import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import { formatDate, formatISODate } from '@1337-us/utils-date'
import { useRef, type PropsWithChildren, useEffect } from 'react'

export interface CalendarHeatmapProps {
  currentDate: Date
  selectedDate: Date
  countValues: CountsValues
  onSelect: (date: Date) => void
}
export const CalendarHeatmap = ({
  currentDate,
  selectedDate,
  onSelect,
  countValues,
}: CalendarHeatmapProps) => {
  const calendarContainerRef = useRef<HTMLDivElement>(null)

  const previousMonthDate = sub(currentDate, { months: 1 })
  const nextMonthDate = add(currentDate, { months: 1 })

  useEffect(() => {
    if (!calendarContainerRef.current) {
      return
    }

    scrollDivToHorizontalCenter(calendarContainerRef.current)
  }, [calendarContainerRef])

  return (
    <div
      ref={calendarContainerRef}
      className="flex gap-3 pb-4  w-full overflow-x-scroll md:overflow-x-auto"
    >
      <Tooltip id="heatmap-tooltip" className={textStyle('body', 'font-regular z-[50]')} />
      <MonthGridView
        referenceDate={previousMonthDate}
        selectedDate={selectedDate}
        values={countValues}
        onSelect={onSelect}
      />
      <MonthGridView
        referenceDate={currentDate}
        selectedDate={selectedDate}
        values={countValues}
        onSelect={onSelect}
      />
      <MonthGridView
        referenceDate={nextMonthDate}
        selectedDate={selectedDate}
        values={countValues}
        onSelect={onSelect}
      />
    </div>
  )
}

interface CountsValues {
  minCount: number
  maxCount: number
  dateCounts: {
    date: string
    count: number
  }[]
}

interface MonthGridView {
  referenceDate: Date
  selectedDate: Date
  values: CountsValues
  onSelect: (date: Date) => void
}
const MonthGridView = ({ referenceDate, selectedDate, onSelect, values }: MonthGridView) => {
  const monthLabel = referenceDate.toLocaleString('en-US', { month: 'long' })
  const weeksInMonth = getDaysOfMonthGroupedByWeeks(
    referenceDate.getFullYear(),
    referenceDate.getMonth() + 1,
  )

  return (
    <div className="flex flex-col gap-1">
      <GridDayLabels />
      {weeksInMonth.map((week, index) => (
        <WeekRowView
          key={`${referenceDate.getDate()}-week-${index}`}
          days={week}
          values={values}
          selectedDate={selectedDate}
          onSelect={onSelect}
          className={clsx({
            'justify-start': index > 0,
            'justify-end': index === 0,
          })}
        />
      ))}
      <div className={textStyle('body', 'font-bold text-center pt-2')}>{monthLabel}</div>
    </div>
  )
}

interface WeekRowViewProps {
  days: Date[]
  selectedDate: Date
  values: CountsValues
  onSelect: (date: Date) => void
  className?: string
}
const WeekRowView = ({ days, selectedDate, values, onSelect, className }: WeekRowViewProps) => {
  return (
    <div className={twMerge('flex gap-1', className)}>
      {days.map((day) => {
        const currentDayCount =
          values.dateCounts.find((value) => value.date === formatISODate(day))?.count ?? 0
        const ratio = calcRatio(values.minCount, values.maxCount, currentDayCount)
        return (
          <HeatmapTile
            key={`day-item-${day.getDate()}`}
            date={day}
            count={currentDayCount}
            ratio={ratio}
            onSelect={onSelect}
            highlight={formatISODate(selectedDate) === formatISODate(day)}
          ></HeatmapTile>
        )
      })}
    </div>
  )
}

interface HeatmapTileProps {
  date: Date
  ratio: number
  count: number
  onSelect: (date: Date) => void
  highlight?: boolean
}
const HeatmapTile = ({
  date,
  ratio,
  count,
  highlight,
  onSelect,
}: PropsWithChildren<HeatmapTileProps>) => {
  const itemStyles = clsx(
    'flex items-center justify-center border w-6 h-6 cursor-pointer rounded-sm',
    {
      'border-black': highlight,
    },
  )

  return (
    <div className={itemStyles}>
      <button
        onClick={() => onSelect(date)}
        className={clsx({
          'flex w-full h-full': true,
          'bg-gray-100': count === 0,
          'bg-black': count > 0,
          'bg-yellow': highlight,
        })}
        style={{ opacity: ratio > 0 && !highlight ? (count > 0 ? ratio : 1) : 1 }}
        data-tooltip-id="heatmap-tooltip"
        data-tooltip-html={`${formatDate(date)}<br />Total events: ${count}`}
        data-tooltip-place="top"
      ></button>
    </div>
  )
}

const GridDayLabels = () => {
  return (
    <div className="flex gap-1 text-xs">
      <div className="flex items-center justify-center w-6 h-6">Mon</div>
      <div className="flex items-center justify-center w-6 h-6">Tue</div>
      <div className="flex items-center justify-center w-6 h-6">Wed</div>
      <div className="flex items-center justify-center w-6 h-6">Thu</div>
      <div className="flex items-center justify-center w-6 h-6">Fri</div>
      <div className="flex items-center justify-center w-6 h-6">Sat</div>
      <div className="flex items-center justify-center w-6 h-6">Sun</div>
    </div>
  )
}

const calcRatio = (min: number, max: number, current: number) => {
  return Math.max((current - min) / (max - min) ?? 0, 0.2)
}

const scrollDivToHorizontalCenter = (element: HTMLDivElement | null) => {
  if (element) {
    // Calculate the scroll position to center the element
    const scrollLeft = (element.scrollWidth - element.clientWidth) / 2

    // Scroll the element to the horizontal center
    element.scroll({
      left: scrollLeft,
      behavior: 'smooth', // Smoothly scroll
    })
  }
}
