'use client'
import * as React from 'react'

// Define the state and action types
type State = { isLightboxOpen: boolean }
type Action = { type: 'open-lightbox' } | { type: 'close-lightbox' }

// Define the context type
type LightboxContextType = {
  state: State
  dispatch: React.Dispatch<Action>
}

// Create the context with an undefined initial value and assert the correct type
const LightboxContext = React.createContext<LightboxContextType | undefined>(undefined)

// The reducer function
const lightboxRootReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'open-lightbox':
      return { ...state, isLightboxOpen: true }
    case 'close-lightbox':
      return { ...state, isLightboxOpen: false }
  }
}

// The provider component
const LightboxProvider = ({ children }: React.PropsWithChildren) => {
  const [state, dispatch] = React.useReducer(lightboxRootReducer, {
    isLightboxOpen: false,
  })
  const value = { state, dispatch }
  return <LightboxContext.Provider value={value}>{children}</LightboxContext.Provider>
}

// Custom hook to use the context
const useLightboxContext = () => {
  const context = React.useContext(LightboxContext)
  if (context === undefined) {
    throw new Error('useLightboxContext must be used within an LightboxProvider')
  }
  return context
}

export { LightboxProvider, useLightboxContext }
