import type { RowData } from '@tanstack/react-table'
import type { DataTableProps } from './data-table'
import { DataTable } from './data-table'

export interface TableQueryAbstraction<Data extends RowData> {
  data?: Data[]
  isLoading: boolean
  isError: boolean
}

export interface DataQueryTableProps<Data extends RowData>
  extends Omit<DataTableProps<Data>, 'data'> {
  dataQuery: TableQueryAbstraction<Data>
}

export const DataQueryTable = <Data extends object>({
  dataQuery,
  columns,
  ...rest
}: DataQueryTableProps<Data>) => {
  const { data, isLoading, isError } = dataQuery

  return (
    <DataTable
      columns={columns}
      data={data ?? []}
      isLoading={isLoading}
      isError={isError}
      {...rest}
    />
  )
}
