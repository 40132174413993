'use client'

import { signIn } from 'next-auth/react'
import { Button } from './button'
import { useAmplitude } from '@1337-us/utils/amplitude'

export const SignInButton = ({ callbackUrl }: { callbackUrl?: string }) => {
  const amplitude = useAmplitude()

  const handleClick = () => {
    amplitude.track('sign_in_started', { provider: 'google' })
    signIn('google', {
      callbackUrl,
    })
  }

  return <Button onClick={handleClick}>Login with Google</Button>
}
