'use client'

import { twMerge } from 'tailwind-merge'
import { textStyle } from '../utils/text-styles'

export interface CheckboxProps {
  id?: string
  label?: string
  checked?: boolean
  onChange?: (checked?: boolean) => void
  className?: string
}
export const Checkbox = ({ id, label, checked, onChange, className }: CheckboxProps) => (
  <div className={twMerge('flex items-center gap-1', className)}>
    <input
      id={id}
      type="checkbox"
      checked={checked}
      className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
      onChange={({ target }) => onChange?.(target.checked)}
    />
    <label htmlFor={id} className={textStyle('mono')}>
      {label}
    </label>
  </div>
)
