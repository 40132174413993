import type { schema as as } from '@1337-us/services/automation'
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from 'date-fns'

export const isPromptStucked = (promptResult?: as.PromptResult) => {
  return (
    promptResult?.status === 'prompting' &&
    new Date(promptResult?.createdAt) < new Date(Date.now() - 1000 * 60 * 5)
  )
}
// Function to get days of a given month grouped by weeks
export const getDaysOfMonthGroupedByWeeks = (year: number, month: number) => {
  // Calculate the first and last day of the month
  const firstDayOfMonth = startOfMonth(new Date(year, month - 1))
  const lastDayOfMonth = endOfMonth(firstDayOfMonth)

  // Adjust to the start of the first week and the end of the last week
  const startOfFirstWeek = startOfWeek(firstDayOfMonth, { weekStartsOn: 1 })
  const endOfLastWeek = endOfWeek(lastDayOfMonth, { weekStartsOn: 1 })

  // Initialize variables for iteration
  let currentWeekStart = startOfFirstWeek
  const weeks = []

  // Iterate over the weeks within the month
  while (currentWeekStart <= endOfLastWeek) {
    const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 })

    // Get all days within the current week interval
    const daysOfWeek = eachDayOfInterval({ start: currentWeekStart, end: currentWeekEnd })

    weeks.push(daysOfWeek) // Add the days of the current week to the weeks array
    currentWeekStart = addDays(currentWeekStart, 7) // Move to the start of the next week
  }

  return weeks.map((week) => week.filter((day) => day >= firstDayOfMonth && day <= lastDayOfMonth))
}
