'use client'

import React, { useCallback, useEffect } from 'react'
import { init, track, Identify, identify } from '@amplitude/analytics-browser'
import { useSession } from 'next-auth/react'
import type { Session } from 'next-auth'

type AmplitudeContextType = {
  track: typeof track
}
export const AmplitudeContext = React.createContext<AmplitudeContextType | undefined>(undefined)

export interface AmplitudeProviderProps {
  apiKey: string
  onSession?: (session: Session, identify: Identify) => Promise<void>
  children: React.ReactNode
}
export const AmplitudeProvider = ({ apiKey, onSession, children }: AmplitudeProviderProps) => {
  const session = useSession()

  const handleSession = useCallback(
    async (session: Session) => {
      if (!onSession) {
        return
      }

      const value = new Identify()
      await onSession(session, value)

      // TODO - I need to test identify first, then I can move to setUserId (maybe it is not necessary at all)
      if (value['_properties']['$set']) {
        if ('user_id' in value['_properties']['$set']) {
          const userId = value['_properties']['$set']['user_id'] as string
          identify(value, { user_id: userId })
          return
        }
      }

      identify(value)
    },
    [onSession],
  )

  useEffect(() => {
    init(apiKey, undefined, {
      defaultTracking: {
        sessions: true,
      },
    })
  }, [apiKey])

  useEffect(() => {
    if (session.status === 'authenticated' && session.data) {
      handleSession(session.data)
    }
  }, [onSession, session.status, session.data])

  return (
    <AmplitudeContext.Provider
      value={{
        track,
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  )
}

export const useAmplitude = () => {
  const context = React.useContext(AmplitudeContext)
  if (context === undefined) {
    throw new Error('useAmplitude must be used within an AmplitudeProvider')
  }
  return context
}
