'use client'

import type { ButtonProps as BaseButtonProps } from '@nextui-org/react'
import { useButton, Spinner } from '@nextui-org/react'
import { forwardRef } from 'react'
import type { VariantProps } from 'tailwind-variants'
import { tv } from 'tailwind-variants'
import { twMerge } from 'tailwind-merge'
import { textStyle } from '../utils/text-styles'

const buttonStyles = tv({
  base: 'flex justify-center items-center gap-2 rounded outline-none disabled:opacity-50 disabled:pointer-events-none',
  variants: {
    variant: {
      solid: 'bg-black text-white hover:opacity-80 active:opacity-100',
      bordered: 'bg-transparent text-black border hover:border-black active:bg-gray-100',
      ghost: 'bg-transparent text-black border-0 hover:bg-gray-100 active:bg-transparent ',
    },
    size: {
      sm: twMerge(textStyle('body'), 'px-2 py-1 min-h-[28px]'),
      md: twMerge(textStyle('body'), 'px-4 py-2'),
      lg: twMerge(textStyle('body'), 'px-6 py-3'),
    },
  },
  defaultVariants: {
    variant: 'solid',
    size: 'md',
  },
})

export type ButtonProps = Omit<BaseButtonProps & VariantProps<typeof buttonStyles>, 'ref'>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    domRef,
    children,
    spinnerSize,
    spinner = <Spinner color="current" size={spinnerSize} className="m-[2px]" />,
    spinnerPlacement,
    startContent,
    endContent,
    isLoading,
    isIconOnly,
    getButtonProps,
  } = useButton({
    ref,
    ...props,
  })

  const buttonProps = getButtonProps()
  const className = twMerge(
    buttonProps.className,
    buttonStyles({
      variant: props.variant,
      size: props.size,
      className: props.className,
    }),
  )

  return (
    <button ref={domRef} {...buttonProps} className={className}>
      {startContent}
      {isLoading && spinnerPlacement === 'start' && spinner}
      {!(isLoading && isIconOnly) && children}
      {isLoading && spinnerPlacement === 'end' && spinner}
      {endContent}
    </button>
  )
})
