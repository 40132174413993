'use client'

import { Check, Copy } from 'lucide-react'
import { useState } from 'react'
import { useCopyToClipboard } from 'usehooks-ts'
import type { ButtonProps } from './button'
import { Button } from './button'
import { Tooltip } from 'react-tooltip'

export interface CopyButtonProps extends ButtonProps {
  label?: string
  value: string
}
export const CopyButton = ({ label, value, ...rest }: CopyButtonProps) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [, copy] = useCopyToClipboard()

  const handleCopy = () => {
    copy(value)
    setShowSuccess(true)

    setTimeout(() => {
      setShowSuccess(false)
    }, 1000)
  }

  const copyButton = (
    <Button
      onClick={handleCopy}
      variant="bordered"
      size="sm"
      isIconOnly={!label}
      data-tooltip-id="copy-button"
      {...rest}
    >
      {label} {showSuccess ? <Check /> : <Copy />}
    </Button>
  )

  if (!label) {
    return (
      <>
        <Tooltip id="copy-button" content="Copy" />
        {copyButton}
      </>
    )
  }

  return copyButton
}
