import { twMerge } from 'tailwind-merge'
import { tv } from 'tailwind-variants'

export const baseTextStyle = tv({
  base: 'antialiased',
  variants: {
    variant: {
      mono: 'font-mono',
      body: 'font-body',
    },
    size: {
      base: 'text-base',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    },
  },
  defaultVariants: {
    variant: 'body',
    size: 'base',
  },
})

export type BaseTextStyleVariant = keyof typeof baseTextStyle.variants.variant
export type BaseTextStyleSize = keyof typeof baseTextStyle.variants.size

const definedTextStyles = {
  'title-01': baseTextStyle({ variant: 'body', size: '2xl', className: 'font-bold uppercase' }),
  'title-02': baseTextStyle({ variant: 'body', size: 'xl', className: 'font-bold uppercase' }),
  'title-03': baseTextStyle({ variant: 'body', size: 'lg', className: 'font-semibold' }),
  'title-04': baseTextStyle({ variant: 'body', size: 'md', className: 'font-semibold uppercase' }),

  // 'body-md': baseTextStyle({ variant: 'body', size: 'md', className: 'font-semibold' }),
  body: baseTextStyle({ variant: 'body', size: 'base', className: 'font-semibold' }),

  // 'mono-md': baseTextStyle({ variant: 'mono', size: 'md', className: 'font-semibold uppercase' }),
  mono: baseTextStyle({ variant: 'mono', size: 'base', className: 'font-semibold uppercase' }),
}

export type TextStyleVariant = keyof typeof definedTextStyles

export const textStyle = (variant: TextStyleVariant, className?: string) => {
  return twMerge(definedTextStyles[variant], className)
}
