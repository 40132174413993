'use client'

import React from 'react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental'
import { NextUIProvider } from '@nextui-org/react'
import { SessionProvider } from 'next-auth/react'
import { AmplitudeProvider } from '@1337-us/utils/amplitude'
import { LightboxProvider } from '@1337-us/admin-components'

export const Providers = ({ children }: React.PropsWithChildren) => {
  const [client] = React.useState(new QueryClient())

  return (
    <SessionProvider>
      <AmplitudeProvider
        apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string}
        onSession={async (session, identify) => {
          if (session.user) {
            identify.set('user_id', session.user.id)
            if (session.user.email) {
              identify.set('user_email', session.user.email)
            }
          }
        }}
      >
        <NextUIProvider>
          <QueryClientProvider client={client}>
            <LightboxProvider>
              <ReactQueryStreamedHydration>{children}</ReactQueryStreamedHydration>
              {process.env.NODE_ENV === 'development' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </LightboxProvider>
          </QueryClientProvider>
        </NextUIProvider>
      </AmplitudeProvider>
    </SessionProvider>
  )
}
