'use client'

import { useEffect } from 'react'
import { useAmplitude } from './amplitude-context'

export interface ScreenTrackProps {
  eventName: string
  eventProperties?: Record<string, string | number | boolean>
}
export const ScreenTrack = ({ eventName, eventProperties }: ScreenTrackProps) => {
  const amplitude = useAmplitude()

  useEffect(() => {
    amplitude.track(eventName, eventProperties)
  }, [])

  return null
}
