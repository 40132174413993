import { format, parse, isValid, isMatch } from 'date-fns'

export const DEFAULT_DATE_FORMAT = 'MM-dd-yyyy'
export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const DEFAULT_TIME_FORMAT = 'hh:mm aaa'
export const DEFAULT_DATE_TIME_FORMAT = 'MM-dd-yyyy hh:mm aaa'

/**
 * Formats a date object to a string in the default date format.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatDate = (date: Date, dateFormat: string = DEFAULT_DATE_FORMAT): string => {
  if (isValid(date)) {
    return format(date, dateFormat)
  }
  return ''
}

/**
 * Formats a date object to a string in the ISO date format.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatISODate = (date: Date): string => {
  if (isValid(date)) {
    return format(date, ISO_DATE_FORMAT)
  }
  return ''
}

/**
 * Parses a date string to a Date object.
 * @param {string} date - The date string to parse.
 * @returns {Date} The parsed Date object.
 */
export const parseDateFromString = (date: string): Date | null => {
  if (isMatch(date, ISO_DATE_FORMAT)) {
    const parsedDate = parse(date, ISO_DATE_FORMAT, new Date())
    if (isValid(parsedDate)) {
      return parsedDate
    }
  }

  if (isMatch(date, DEFAULT_DATE_FORMAT)) {
    const parsedDate = parse(date, DEFAULT_DATE_FORMAT, new Date())
    if (isValid(parsedDate)) {
      return parsedDate
    }
  }

  return null
}

/**
 * Formats a date object to a string in the default date time format.
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
export const formatDateTime = (date: Date): string => {
  if (isValid(date)) {
    return format(date, DEFAULT_DATE_TIME_FORMAT)
  }
  return ''
}

/**
 * Parses a date string in the default date time format to a Date object.
 * @param {string} date - The date string to parse.
 * @returns {Date} The parsed Date object.
 */
export const parseDateTimeFromString = (date: string): Date | null => {
  const result = parse(date, DEFAULT_DATE_TIME_FORMAT, new Date())
  if (isValid(result)) {
    return result
  }

  return null
}

/**
 * Parse occurred_at from Python Backend to date and time string.
 */
export const parseOccurredAt = (occurredAt: string) => {
  const [date, time] = occurredAt.slice(0, 19).split('T')

  return {
    date,
    time,
  }
}

/**
 * Formats a string time to a string in the common time format.
 * @param {string} time - The time to format. The time should be in the format '13:41:50'.
 * @returns {string} The formatted time string.
 */
export const formatTime = (time: string): string => {
  const arr = time.split(':')

  let hours = parseInt(arr[0], 10)
  const pm = hours >= 12
  hours = hours % 12
  hours = hours ? hours : 12

  const hh = hours < 10 ? `0${hours}` : `${hours}`
  const mm = arr[1]
  const aaa = pm ? 'pm' : 'am'

  return `${hh}:${mm} ${aaa}`
}
