import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

export const InfiniteScroll = <T,>(props: {
  items: T[]
  renderItem: (item: T, index: number) => ReactNode
  itemsPerPage: number
  className?: string
}) => {
  const [numberOfVisibleItems, setNumberOfVisibleItems] = useState(props.itemsPerPage)

  const items = useMemo(
    () => props.items.slice(0, numberOfVisibleItems),
    [props.items, numberOfVisibleItems],
  )

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      setNumberOfVisibleItems((prev) => prev + props.itemsPerPage)
    }
  }, [numberOfVisibleItems, props.itemsPerPage, inView])

  return (
    <div className={props.className}>
      {items.map(props.renderItem)}
      {props.items.length > numberOfVisibleItems && <div ref={ref} />}
    </div>
  )
}
