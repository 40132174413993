'use client'

import { textStyle } from '@1337-us/io-components/util'
import clsx from 'clsx'
import type { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

export type SwitchItem = {
  label: string
  value: string
}

export interface SwitchProps {
  label?: string
  value?: string | null
  options: SwitchItem[]
  onChange?: (value?: string | null) => void
  className?: string
}
export const Switch = ({ label, value, onChange, options, className }: SwitchProps) => {
  const toggleSwitch = (type?: string | null) => {
    onChange?.(type === value ? null : type)
  }

  return (
    <div className={twMerge(textStyle('title-02', 'flex pb-4 items-center'), className)}>
      {label && <div className="pr-2">{label}</div>}
      {options.map((option) => (
        <SwitchButton
          key={option.value}
          onClick={() => toggleSwitch(option.value)}
          isActive={value === option.value}
        >
          {option.label?.toUpperCase()}
        </SwitchButton>
      ))}
    </div>
  )
}

interface SwitchButtonProps {
  isActive: boolean
  onClick: () => void
}
const SwitchButton = ({ isActive, onClick, children }: PropsWithChildren<SwitchButtonProps>) => {
  return (
    <button
      className={clsx({
        'cursor-pointer hover:underline opacity-30': !isActive,
        'cursor-default text-black': isActive,
      })}
      onClick={() => {
        if (!isActive) {
          onClick()
        }
      }}
    >
      {children}
    </button>
  )
}
