'use client'

import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import { ChevronDown } from 'lucide-react'
import { textStyle } from '../utils/text-styles'
import { tv } from 'tailwind-variants'
import { twMerge } from 'tailwind-merge'

const accordionButtonStyle = tv({
  base: 'flex items-center gap-1 uppercase font-bold hover:text-gray-700',
  variants: {
    size: {
      small: textStyle('body'),
      medium: textStyle('title-04'),
      large: textStyle('title-02'),
    },
  },
})

export interface AccordionProps {
  label: React.ReactNode
  size: 'small' | 'medium' | 'large'
  defaultOpen?: boolean
  rightHeader?: React.ReactNode
  className?: string
}
export const Accordion = ({
  className,
  label,
  size = 'large',
  defaultOpen,
  rightHeader,
  children,
}: PropsWithChildren<AccordionProps>) => {
  const [collapsed, setCollapsed] = useState(!defaultOpen)
  return (
    <div className={twMerge('w-full flex flex-col', className)}>
      <div className="flex gap-2">
        <button
          className={accordionButtonStyle({ size })}
          onClick={() => setCollapsed((current) => !current)}
        >
          <ChevronDown
            size={chevronSize(size)}
            style={{
              transform: `rotate(${collapsed ? 0 : 180}deg)`,
              transition: 'transform 0.3s ease-in-out',
            }}
          />
          {label}
        </button>

        {rightHeader}
      </div>

      {!collapsed && <div className="py-2 px-0 md:px-2">{children}</div>}
    </div>
  )
}

const chevronSize = (size: 'small' | 'medium' | 'large') => {
  switch (size) {
    case 'small':
      return 20
    case 'medium':
      return 30
    case 'large':
      return 60
  }
}
